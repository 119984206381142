import { useEffect } from 'react';

export const useAsyncEffect = <T>(cb1: () => Promise<T>, cb2: (value: T) => void) => {
  useEffect(() => {
    let isActive = true;
    (async () => {
      const value = await cb1();
      if (isActive) {
        cb2(value);
      }
    })();

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
