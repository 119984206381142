import { orderBy } from 'lodash';
import { action, makeObservable, observable } from 'mobx';

import { ITheMovieDbResponseRaw } from '../interfaces/ITheMovieDbResponseRaw';
import { TheMovieDbMovie } from '../models/TheMovieDbMovie';
import { Nullable } from '../types/assert';

export interface ISearchFilmsStore {
  isFoundByName: boolean;
  filmName: string;
  filmYear: Nullable<number>;
  prompt: string;
  input: string;
  movies: TheMovieDbMovie[];
  setPrompt: (prompt: string) => void;
  setInput: (input: string) => void;
  setFilmName: (name: string) => void;
  setFilmYear: (year: Nullable<number>) => void;
  setMovies: (movies: TheMovieDbMovie[]) => void;
  setResponse: (response: ITheMovieDbResponseRaw) => void;
}

export class SearchFilmsStore implements ISearchFilmsStore {
  @observable isFoundByName = true;
  @observable filmName = '';
  @observable filmYear: Nullable<number> = null;
  @observable prompt = '';
  @observable input = '';
  @observable movies: TheMovieDbMovie[] = [];
  #lastResponse: Nullable<ITheMovieDbResponseRaw> = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setInput(input: string) {
    this.input = input;
  }

  @action
  setFilmName(name: string) {
    this.filmName = name;
    this.isFoundByName = true;
  }

  @action
  setFilmYear(year: Nullable<number>) {
    this.filmYear = year;
  }

  @action
  setPrompt(prompt: string) {
    this.prompt = prompt;
  }

  @action
  setResponse(response: ITheMovieDbResponseRaw) {
    this.#lastResponse = response;
    this.movies = orderBy(
      response.results.map(movie => new TheMovieDbMovie(movie)),
      ['popularity'],
      ['desc']
    );
    this.isFoundByName = this.movies.length !== 0;
  }

  @action
  setMovies(movies: TheMovieDbMovie[]) {
    this.movies = movies;
  }
}
