import { FC, useCallback } from 'react';

import { Alert, AlertTitle, Box, Button, Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { GroupsMoviesLsApi } from '../api/GroupsMoviesLsApi';
import { MoviesChatGptApi } from '../api/MoviesChatGptApi';
import { FavoriteFilms } from '../components/templates/FavoriteFilms';
import { useAsyncHandler } from '../hooks/useAsyncHandler';
import { useRootStore } from '../stores/root-store-provider';

export const FavoritesPage: FC = () => {
  const { favoriteFilmsStore } = useRootStore();

  const handleGroupClick = useCallback(async () => {
    const response = await MoviesChatGptApi.groupMovies(favoriteFilmsStore.getMoviesNames());
    const groups = await favoriteFilmsStore.setGroupedMovies(response);
    if (!groups.length) {
      throw new Error('Unsuccessful parse the response');
    }
    favoriteFilmsStore.setGroups(groups);
  }, [favoriteFilmsStore]);

  const { isLoading, error, asyncHandler } = useAsyncHandler(handleGroupClick);

  const handleUngroupClick = async () => {
    favoriteFilmsStore.setGroups([]);
    favoriteFilmsStore.groupMovies(null, favoriteFilmsStore.getMoviesNames());
    await GroupsMoviesLsApi.saveGroups([]);
  };

  return (
    <Container>
      <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', ml: 3, mr: 3 }}>
        <Typography variant="h1">Favorites</Typography>
        <Box sx={{ display: 'none' }}>
          <Button disabled={isLoading} size="small" variant="outlined" onClick={asyncHandler}>
            {isLoading ? 'Loading...' : 'Try Auto Group'}
          </Button>
          <Button
            color="secondary"
            disabled={isLoading}
            size="small"
            sx={{ ml: 1 }}
            variant="outlined"
            onClick={handleUngroupClick}
          >
            Ungroup
          </Button>
        </Box>
      </Box>
      {error && (
        <Box sx={{ mb: 1, ml: 3, mr: 3, mt: 1 }}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        </Box>
      )}
      <FavoriteFilms />
    </Container>
  );
};
