import { FC, useEffect } from 'react';

import { Alert, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { FilmsList } from './FilmsList';
import { LoadingState } from './LoadingState';
import { useSearchFilmsByName } from '../../api/hooks/useSearchFilmsByName';
import { useRootStore } from '../../stores/root-store-provider';

interface Props {
  onSubmitByAi: () => void;
}

export const FindFilmsByNames: FC<Props> = observer(({ onSubmitByAi }) => {
  const { searchFilmsStore } = useRootStore();
  const { movies, filmName, filmYear, isFoundByName } = searchFilmsStore;

  const query = useSearchFilmsByName(filmName, filmYear);

  useEffect(() => {
    if (query.data) {
      searchFilmsStore.setFilmName('');
      searchFilmsStore.setFilmYear(null);
      searchFilmsStore.setResponse(query.data);
      if (query.data.results.length === 0) {
        onSubmitByAi();
      }
    }
  }, [onSubmitByAi, query.data, searchFilmsStore]);

  return (
    <LoadingState {...query}>
      {isFoundByName ? (
        <FilmsList movies={movies} />
      ) : (
        <Alert severity="info" sx={{ m: 1 }}>
          No films find by name. Try to <Button onClick={onSubmitByAi}>use AI GPT</Button> to search by other criteria.
        </Alert>
      )}
    </LoadingState>
  );
});
