import React, { FC } from 'react';

import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { MENU_PAGES } from './MENU_PAGES';

interface Props {
  onMenuClick: (link: string) => void;
}

export const TopMenu: FC<Props> = ({ onMenuClick }) => (
  <Box sx={{ display: { md: 'flex', xs: 'none' }, flexGrow: 1, justifyContent: 'space-evenly' }}>
    {MENU_PAGES.map(({ link, title, target, icon }) => (
      <Button
        key={link}
        component={Link}
        startIcon={icon}
        sx={{ color: target ? '#666' : '#000', my: 2 }}
        target={target}
        to={link}
        onClick={() => onMenuClick(link)}
      >
        {title}
      </Button>
    ))}
  </Box>
);
