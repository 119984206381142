import { FC } from 'react';

import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { AiMoviesTabs } from './AiMoviesTabs';
import { LoadingState } from './LoadingState';
import { useSearchFilmsByAi } from '../../api/hooks/useSearchFilmsByAi';
import { useRootStore } from '../../stores/root-store-provider';

export const FindFilmsByAi: FC = observer(() => {
  const { searchFilmsStore } = useRootStore();
  const { prompt } = searchFilmsStore;

  const query = useSearchFilmsByAi(prompt);

  return (
    <LoadingState {...query}>
      {query.data && (
        <Box sx={{ m: 1, p: 1 }}>
          <AiMoviesTabs content={query.data} />
        </Box>
      )}
    </LoadingState>
  );
});
