import axios from 'axios';

import { ChatLsApi } from './ChatLsApi';
import {
  CHAT_GPT_API_KEY,
  CHAT_GPT_CHAT_COMPLETIONS_API_URL,
  CHAT_GPT_COMPLETIONS_API_URL,
  CHAT_GPT_MODELS_API_URL
} from '../constants/CHAT_GPT';
import { Amplitude } from '../services/Amplitude';

export namespace ChatGptApi {
  const headers = {
    Authorization: `Bearer ${CHAT_GPT_API_KEY}`
  };

  export const getModelsList = async () => {
    const { data } = await axios.get(CHAT_GPT_MODELS_API_URL, {
      headers
    });

    return data;
  };

  export interface IMessage {
    content: string;
    role: 'user' | 'assistant' | 'system';
  }

  export const sendChatMessage = async (
    messages: Array<IMessage>,
    model = 'gpt-3.5-turbo',
    temperature = 0.85
  ): Promise<string> => {
    Amplitude.track('FF__GPT__SEND', { messages, model, temperature });

    await Promise.all(messages.map(ChatLsApi.addMessage));

    const { data } = await axios.post(
      CHAT_GPT_CHAT_COMPLETIONS_API_URL,
      {
        messages,
        model,
        temperature
      },
      {
        headers
      }
    );

    const answer = data.choices[0].message.content as string;
    await ChatLsApi.addMessage({ content: answer, role: 'assistant' });

    Amplitude.track('FF__GPT__RECEIVED', { answer, data, messages, model, temperature });

    return answer;
  };

  export const sendMessage = async (prompt: string) => {
    const { data } = await axios.post(
      CHAT_GPT_COMPLETIONS_API_URL,
      {
        logprobs: null,
        max_tokens: 7,
        model: 'davinci',
        n: 1,
        prompt,
        stop: '',
        stream: false,
        temperature: 0,
        top_p: 1
      },
      {
        headers
      }
    );

    return data;
  };
}
