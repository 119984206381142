import axios from 'axios';

import { THE_MOVIE_DB_API_KEY, THE_MOVIE_DB_MOVIE, THE_MOVIE_DB_SEARCH_MOVIES } from '../constants/THE_MOVIE_DB';
import { ITheMovieDbDetailedMovieRaw, ITheMovieDbResponseRaw } from '../interfaces/ITheMovieDbResponseRaw';
import { Nullable } from '../types/assert';

export namespace TheMovieDbApi {
  export const searchMovies = async (query: string, year: Nullable<number>): Promise<ITheMovieDbResponseRaw> => {
    const params = new URLSearchParams(THE_MOVIE_DB_SEARCH_MOVIES);
    params.set('api_key', THE_MOVIE_DB_API_KEY);
    params.set('query', query);
    if (year) {
      params.set('year', `${year}`);
    }

    const url = decodeURIComponent(params.toString());

    const response = await axios(url);
    if (response.status !== 200) {
      throw new Error(`TheMovieDb.searchMovies: ${response.status}`);
    }

    return response.data;
  };

  export const getMovie = async (id: number): Promise<ITheMovieDbDetailedMovieRaw> => {
    const params = new URLSearchParams(`${THE_MOVIE_DB_MOVIE}/${id}?`);
    params.set('api_key', THE_MOVIE_DB_API_KEY);
    const url = decodeURIComponent(params.toString());

    const response = await axios(url);
    if (response.status !== 200) {
      throw new Error(`TheMovieDb.searchMovies: ${response.status}`);
    }

    return response.data;
  };
}
