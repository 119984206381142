import AsyncStorage from '@react-native-async-storage/async-storage';

export class LocalStorageApi<T, C> {
  constructor(private keyPrefix: string, private mapper: (value: T) => C) {}

  async removeEntry(id: string) {
    await AsyncStorage.removeItem(this.generateKey(id));
  }

  async saveEntry(id: string, raw: T) {
    await AsyncStorage.setItem(this.generateKey(id), JSON.stringify(raw));
  }

  async getEntry(movieId: string) {
    const raw = await AsyncStorage.getItem(this.generateKey(movieId));
    if (!raw) {
      return null;
    }

    const obj: T = JSON.parse(raw);

    return this.mapper(obj);
  }

  private generateKey = (id: string) => `${this.keyPrefix}__${id}`;
}
