import { FAVORITES_KEY } from '../constants/LOCAL_STORAGE_KEYS';
import FAVORITES from '../json/FAVORITES.json';

export namespace DefaultStorage {
  export const fillDefaultFavoriteMovies = () => {
    localStorage.setItem(FAVORITES_KEY, JSON.stringify(FAVORITES));
    FAVORITES.forEach((id: string) => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const json = require(`../json/F__${id}.json`);
      localStorage.setItem(`F__${id}`, JSON.stringify(json));
    });
  };
}
