import { FC } from 'react';

import { Box, FormControl } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';

import { FindFilmsByAi } from './FindFilmsByAi';
import { FindFilmsByNames } from './FindFilmsByNames';
import { Amplitude } from '../../services/Amplitude';
import { ChatGptMoviesListsService } from '../../services/ChatGptMoviesListsService';
import { useRootStore } from '../../stores/root-store-provider';
import { SearchField } from '../atoms/SearchField';
import { SearchExamples } from '../molecules/SearchExamples';

export const FindFilmsForm: FC = observer(() => {
  const { searchFilmsStore } = useRootStore();
  const { input } = searchFilmsStore;

  const handleInputChange = (value: string) => {
    Amplitude.track('FF__SEARCH__CHANGE', { input: value });
    searchFilmsStore.setInput(value);
  };

  const handleInputClear = () => {
    Amplitude.track('FF__SEARCH__CLEAR');
    searchFilmsStore.setInput('');
    searchFilmsStore.setFilmName('');
    searchFilmsStore.setPrompt('');
    searchFilmsStore.setMovies([]);
  };

  const handleSubmitByName = () => {
    Amplitude.track('FF__SEARCH__BY_NAME__SUBMIT', { input });
    window.history.pushState(`search ${input}`, `${input}`, ChatGptMoviesListsService.buildUrl(input, null));
    searchFilmsStore.setFilmName(input);
    searchFilmsStore.setPrompt('');
    searchFilmsStore.setMovies([]);
    searchFilmsStore.setMovies([]);
  };

  const handleSubmitByAi = () => {
    Amplitude.track('FF__SEARCH__BY_AI__SUBMIT', { input });
    searchFilmsStore.setPrompt(input);
    searchFilmsStore.setFilmName('');
    searchFilmsStore.setMovies([]);
  };

  return (
    <Box>
      <FormControl sx={{ width: '100%' }}>
        <SearchField
          fullWidth
          placeholder="Movie Name or Description, Plot, Actors, Time, etc"
          value={input}
          onChange={e => handleInputChange(e.target.value)}
          onClear={handleInputClear}
          onSubmitByAi={handleSubmitByAi}
          onSubmitByName={handleSubmitByName}
        />
      </FormControl>
      <Paper elevation={24} sx={{ mb: 2, mt: 2, pb: 2, pt: 2 }}>
        <FindFilmsByNames onSubmitByAi={handleSubmitByAi} />
        <FindFilmsByAi />
        <Box>
          <Typography sx={{ ml: 2, mr: 2 }} variant="h5">
            Search examples:
          </Typography>
          <SearchExamples />
        </Box>
      </Paper>
    </Box>
  );
});
