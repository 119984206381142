import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { StyledBadge } from './StyledBadge';
import { ROUTES } from '../../../constants/ROUTES';
import { LogoColor } from '../../atoms/svg/LogoColor';

export const MobileLogo: FC = () => (
  <>
    <Box
      sx={{
        display: { md: 'none', xs: 'flex' }
      }}
    >
      <LogoColor />
    </Box>
    <Typography
      noWrap
      component={Link}
      sx={{
        color: '#666',
        display: { md: 'none', xs: 'flex' },
        flexGrow: 1,
        fontFamily: 'monospace',
        fontWeight: 700,
        letterSpacing: '.3rem',
        mr: 2,
        textDecoration: 'none'
      }}
      to={ROUTES.HOME}
      variant="h5"
    >
      <StyledBadge badgeContent="Beta">Fury Films</StyledBadge>
    </Typography>
  </>
);
