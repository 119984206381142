import { useQuery } from 'react-query';

import { TheMovieDbMovie } from '../../models/TheMovieDbMovie';
import { Nullable } from '../../types/assert';
import { FilmsLocalApi } from '../FilmsLocalApi';
import { TheMovieDbApi } from '../TheMovieDbApi';

export const useMovieDetails = ({ id, idTheMovie }: { id: string; idTheMovie: Nullable<number>; title: string }) =>
  useQuery(['movie-details', id], {
    queryFn: async () => {
      const film = await FilmsLocalApi.getFilm(id);

      if (!film) {
        if (idTheMovie) {
          const raw = await TheMovieDbApi.getMovie(idTheMovie);

          return new TheMovieDbMovie(raw);
        }
        throw new Error('Movie not found.');
      }

      return film;
    },
    refetchOnWindowFocus: false
  });
