import { FC } from 'react';

import { Container } from '@mui/material';
import { Outlet } from 'react-router';

import { ActionsMenu } from '../organisms/AppTopBar/ActionsMenu';
import { AppTopBar } from '../organisms/AppTopBar/AppTopBar';

export const AppLayout: FC = () => (
  <>
    <AppTopBar />
    <ActionsMenu />
    <Container maxWidth="xl" sx={{ mb: 10, pt: 2 }}>
      <main>
        <Outlet />
      </main>
    </Container>
  </>
);
