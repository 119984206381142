import AsyncStorage from '@react-native-async-storage/async-storage';

import { DefaultStorage } from './DefaultStorage';
import { FilmsLocalApi } from './FilmsLocalApi';
import { FAVORITES_KEY, WATCH_LATER_KEY } from '../constants/LOCAL_STORAGE_KEYS';
import { TheMovieDbMovie } from '../models/TheMovieDbMovie';
import { isExist } from '../types/assert';

class KindsFilmsLsApi {
  #key: string;

  constructor(key: string) {
    this.#key = key;
  }

  async loadIsKind(movieId: string) {
    const favoritesIds = await this.#getIdsList();

    return favoritesIds.includes(movieId);
  }

  async getFilms(): Promise<TheMovieDbMovie[]> {
    if (localStorage.getItem(this.#key) === null) {
      DefaultStorage.fillDefaultFavoriteMovies();
    }
    const favoritesIds = await this.#getIdsList();

    const loadPromises = favoritesIds.map(FilmsLocalApi.getFilm);
    const optionalMovies = await Promise.all(loadPromises);

    return optionalMovies.filter(isExist);
  }

  async setKind(movieId: string, isKind: boolean, movie: TheMovieDbMovie) {
    let favoritesIds = await this.#getIdsList();

    if (isKind && !favoritesIds.includes(movieId)) {
      await FilmsLocalApi.saveFilm(movie.id, movie.raw);
      favoritesIds.push(movieId);
    } else if (!isKind && favoritesIds.includes(movieId)) {
      favoritesIds = favoritesIds.filter(favorite => favorite !== movieId);
    }

    await AsyncStorage.setItem(this.#key, JSON.stringify(favoritesIds));
  }

  async #getIdsList(): Promise<string[]> {
    const raw = await AsyncStorage.getItem(this.#key);
    if (!raw) {
      return [];
    }

    return JSON.parse(raw) ?? [];
  }
}

export const favoritesFilmsLsApi = new KindsFilmsLsApi(FAVORITES_KEY);
export const watchLaterFilmsLsApi = new KindsFilmsLsApi(WATCH_LATER_KEY);
