import { FC } from 'react';

import { CardActionArea, Skeleton } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { POSTER_CARD_SIZE } from '../../constants/IMAGES';
import { IMovieParsed } from '../../interfaces/IMovieParsed';

interface Props {
  movie: IMovieParsed;
}

export const LoadingMovieCard: FC<Props> = ({ movie }) => (
  <Card sx={{ m: 1, width: POSTER_CARD_SIZE.WIDTH + 20 }}>
    <CardActionArea component={Link} target="_blank" to={movie.q}>
      <Skeleton
        height={POSTER_CARD_SIZE.HEIGHT}
        sx={{ margin: '0 auto' }}
        variant="rectangular"
        width={POSTER_CARD_SIZE.WIDTH}
      />

      <CardContent>
        <Typography color="text.secondary" variant="h6">
          {movie.filmName}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Skeleton height={40} variant="rectangular" width={POSTER_CARD_SIZE.WIDTH} />
      </CardActions>
    </CardActionArea>
  </Card>
);
