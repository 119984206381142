import * as amplitude from '@amplitude/analytics-browser';
import { EventOptions } from '@amplitude/analytics-types';

import { AMPLITUDE_API_KEY } from '../constants/AMPLITUDE';

export namespace Amplitude {
  export const init = (userId?: string) => {
    amplitude.init(AMPLITUDE_API_KEY, userId, {
      defaultTracking: {
        fileDownloads: true,
        formInteractions: true,
        pageViews: true,
        sessions: true
      }
    });
  };

  export const track = (
    event: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventProperties?: Record<string, any> | undefined,
    eventOptions?: EventOptions
  ) => {
    const location = {
      hash: window.location.hash,
      host: window.location.host,
      hostname: window.location.hostname,
      href: window.location.href,
      origin: window.location.origin,
      pathname: window.location.pathname,
      port: window.location.port,
      protocol: window.location.protocol,
      search: window.location.search
    };

    return amplitude.track(event, { location, ...eventProperties }, eventOptions);
  };
}
