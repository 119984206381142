import { FC } from 'react';

import { Alert, AlertTitle } from '@mui/material';

import { ShowMultilineContent } from './ShowMultilineContent';
import { ChatGptApi } from '../../api/ChatGptApi';

interface Props {
  message: ChatGptApi.IMessage;
}

export const ChatMessage: FC<Props> = ({ message }) => {
  if (message.role === 'user') {
    return (
      <Alert severity="info">
        <AlertTitle>User</AlertTitle>
        <ShowMultilineContent content={message.content} />
      </Alert>
    );
  }

  if (message.role === 'assistant') {
    return (
      <Alert severity="success">
        <AlertTitle>Assistant</AlertTitle>
        <ShowMultilineContent content={message.content} />
      </Alert>
    );
  }

  if (message.role === 'system') {
    return (
      <Alert severity="warning">
        <AlertTitle>System</AlertTitle>
        <ShowMultilineContent content={message.content} />
      </Alert>
    );
  }

  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <ShowMultilineContent content={message.content} />
    </Alert>
  );
};
