import { FC } from 'react';

import Paper from '@mui/material/Paper';

import { ChatGptApi } from '../../api/ChatGptApi';
import { ChatMessage } from '../molecules/ChatMessage';

interface Props {
  messages: ChatGptApi.IMessage[];
}

export const ChatHistory: FC<Props> = ({ messages }) => (
  <Paper sx={{ mb: 10 }}>
    {messages.map(message => (
      <ChatMessage key={message.content} message={message} />
    ))}
  </Paper>
);
