import { FC, HTMLAttributeAnchorTarget, ReactNode } from 'react';

import FeedbackIcon from '@mui/icons-material/Feedback';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

interface IAction {
  link: string;
  name?: string;
  target?: HTMLAttributeAnchorTarget;
  icon?: ReactNode;
}

const actions: IAction[] = [
  { icon: <FeedbackIcon />, link: 'https://forms.gle/Kbw8kq3BWgoTTjKZ6', name: 'Feedback', target: '_blank' }
];

export const ActionsMenu: FC = () => {
  const handleCLick = (action: IAction) => {
    window.open(action.link, action.target);
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDialMenu"
      icon={<SpeedDialIcon />}
      sx={{ bottom: { md: 24, xs: 4 }, position: 'fixed', right: { md: 24, xs: 4 } }}
    >
      {actions.map(action => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleCLick(action)}
        />
      ))}
    </SpeedDial>
  );
};
