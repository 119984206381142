import { useEffect } from 'react';

import { GroupsMoviesLsApi } from '../api/GroupsMoviesLsApi';
import { useRootStore } from '../stores/root-store-provider';

export const useAppInit = () => {
  const { favoriteFilmsStore } = useRootStore();

  useEffect(() => {
    (async () => {
      const groups = await GroupsMoviesLsApi.getGroups();
      if (!groups) {
        return;
      }
      favoriteFilmsStore.setGroups(groups);
    })();
  }, [favoriteFilmsStore]);
};
