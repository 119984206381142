import { FC } from 'react';

import { Box, Chip } from '@mui/material';
import { useNavigate } from 'react-router';

import { ROUTES } from '../../constants/ROUTES';
import { Amplitude } from '../../services/Amplitude';
import { useRootStore } from '../../stores/root-store-provider';

const SEARCH_EXAMPLES = [
  'Any Best movies of all time',
  'Movies about love',
  'The best movies of 90-x',
  'Movies with Tom Hanks',
  'The best movies of 2018',
  'Family-related movies',
  'Best detective movies',
  'Movies about Rome Empire',
  'Fantastic space-related movies',
  'Movies about spy',
  'Gansters Movies',
  'Movies from Marvel Universe',
  'Movies from DC Universe',
  'Top-rated movies on IMDB',
  'Movies like Interstellar',
  'Action movies',
  'Romantic comedies to watch with girlfriend',
  'Horror movies',
  'Kids movies',
  'Top all Oscar-winning movies',
  'Movies set in New York',
  'Animated movies',
  'Documentaries movies',
  'Music Movies',
  'Foreign films',
  'Superhero movies',
  'Movies based on true stories',
  'Science fiction movies',
  'Comedies',
  'Top Comedies',
  'Drama movies'
];

export const SearchExamples: FC = () => {
  const { searchFilmsStore } = useRootStore();
  const navigate = useNavigate();

  const handleClick = (example: string) => {
    Amplitude.track('FF__SEARCH_EXAMPLES__CLICK', { example });
    searchFilmsStore.setInput(example);
    searchFilmsStore.setPrompt(example);
    searchFilmsStore.setFilmName('');
    searchFilmsStore.setMovies([]);
    navigate({
      pathname: ROUTES.SEARCH,
      search: `?q=${encodeURI(example)}`
    });
    window.scrollTo(0, 0);
  };

  return (
    <Box sx={{ borderTop: '1px solid #eee', ml: 2, mr: 2, pt: 1 }}>
      {SEARCH_EXAMPLES.map(example => (
        <Chip label={example} sx={{ m: 1 }} onClick={() => handleClick(example)} />
      ))}
    </Box>
  );
};
