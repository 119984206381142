import { FC } from 'react';

import { Container } from '@mui/material';

import { LoadingMovieCardBox } from './LoadingMovieCardBox';
import { IMovieParsed } from '../../interfaces/IMovieParsed';

interface Props {
  movies: IMovieParsed[];
}

export const AiMoviesBox: FC<Props> = ({ movies }) => (
  <Container sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
    {movies.map(movie => (
      <LoadingMovieCardBox key={movie.filmLink} movie={movie} />
    ))}
  </Container>
);
