import { FC } from 'react';

import { Container, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

export const E404Page: FC = observer(() => (
  <Container>
    <Typography variant="h1">
      Page not found <br />
      ;-(
    </Typography>
  </Container>
));
