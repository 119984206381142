import { useQuery } from 'react-query';

import { Nullable } from '../../types/assert';
import { TheMovieDbApi } from '../TheMovieDbApi';

export const useSearchFilmsByName = (name: string, year: Nullable<number>) =>
  useQuery(['movies-search-by-name', name, year], {
    queryFn: async () => (name ? await TheMovieDbApi.searchMovies(name, year) : ''),
    refetchOnWindowFocus: false
  });
