import { FC, useEffect, useState } from 'react';

import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { ChatGptApi } from '../api/ChatGptApi';
import { ChatLsApi } from '../api/ChatLsApi';
import { ChatHistory } from '../components/organisms/ChatHistory';

export const ChatPage: FC = () => {
  const [messages, setMessages] = useState<ChatGptApi.IMessage[]>([]);

  useEffect(() => {
    (async () => {
      setMessages(await ChatLsApi.getMessages());
    })();
  }, []);

  return (
    <Container sx={{ pb: 20 }}>
      <Typography sx={{ ml: 3, mr: 3 }} variant="h1">
        Chat with GPT AI
      </Typography>
      <ChatHistory messages={messages} />
    </Container>
  );
};
