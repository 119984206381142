import { FC } from 'react';

import { Container } from '@mui/material';

import { FindFilmsForm } from '../organisms/FindFilmsForm';

export const FindFilms: FC = () => (
  <Container>
    <FindFilmsForm />
  </Container>
);
