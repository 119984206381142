import { FC, useEffect } from 'react';

import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { FindFilms } from '../components/templates/FindFilms';
import { useRootStore } from '../stores/root-store-provider';

export const SearchPage: FC = () => {
  const { searchFilmsStore } = useRootStore();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const q = query.get('q') ?? undefined;
    const y = query.get('y') ?? null;
    if (q) {
      searchFilmsStore.setInput(q);
      searchFilmsStore.setFilmName(q);
      searchFilmsStore.setFilmYear(y ? (isNaN(+y) ? null : +y) : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Typography sx={{ ml: 3, mr: 3 }} variant="h1">
        Search Movies
      </Typography>
      <FindFilms />
    </Container>
  );
};
