import { FC } from 'react';

import { Container } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { watchLaterFilmsLsApi } from '../../api/KindsFilmsLsApi';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { useRootStore } from '../../stores/root-store-provider';
import { FilmsGroup } from '../organisms/FilmsGroup';

interface Props {
  todo?: boolean;
}

export const WatchLaterFilms: FC<Props> = observer(() => {
  const { watchLaterFilmsStore } = useRootStore();

  useAsyncEffect(
    async () => await watchLaterFilmsLsApi.getFilms(),
    value => watchLaterFilmsStore.setMovies(value)
  );

  const ungroupedMovies = watchLaterFilmsStore.getMoviesWithoutGroup();

  return (
    <Container>
      <FilmsGroup expanded group="Ungrouped movies" movies={ungroupedMovies} />
    </Container>
  );
});
