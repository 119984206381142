import { FC } from 'react';

import { useMovieDetails } from '../../api/hooks/useMovieDetails';
import { defined, Nullable } from '../../types/assert';
import { FilmDetails } from '../organisms/FilmDetails';
import { LoadingState } from '../organisms/LoadingState';

interface Props {
  id: string;
  idTheMovie: Nullable<number>;
  title: string;
}

export const FilmDetailsContainer: FC<Props> = ({ id, idTheMovie, title }) => {
  const query = useMovieDetails({ id, idTheMovie, title });

  return <LoadingState {...query}>{query.data && <FilmDetails film={defined(query.data)} />}</LoadingState>;
};
