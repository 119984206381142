import React, { FC, useState } from 'react';

import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';

const SHOW_SETTINGS = false;

const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

export const ProfileMenu: FC = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = () => {
    // TODO: implement user menu
    // setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!SHOW_SETTINGS) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="We are using Chat GPT AI">
        <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
          <Avatar sx={{ backgroundColor: 'skyblue' }}>
            <SmartToyIcon sx={{ color: 'white' }} />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        keepMounted
        anchorEl={anchorElUser}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        id="menu-appbar"
        open={Boolean(anchorElUser)}
        sx={{ mt: '45px' }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        onClose={handleCloseUserMenu}
      >
        {settings.map(setting => (
          <MenuItem key={setting} onClick={handleCloseUserMenu}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
