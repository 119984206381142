import { LocalStorageApi } from './LocalStorageApi';
import { TheMovieDbMovieRaw } from '../interfaces/ITheMovieDbResponseRaw';
import { TheMovieDbMovie } from '../models/TheMovieDbMovie';

export namespace FilmsLocalApi {
  const ls = new LocalStorageApi('F', (value: TheMovieDbMovieRaw) => new TheMovieDbMovie(value));
  export const removeFilm = async (movie: TheMovieDbMovie) => await ls.removeEntry(movie.id);
  export const saveFilm = async (id: string, raw: TheMovieDbMovieRaw) => await ls.saveEntry(id, raw);
  export const getFilm = async (movieId: string) => await ls.getEntry(movieId);
}
