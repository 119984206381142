import { FC, useCallback, useRef } from 'react';

import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Box, Button, Paper, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { AiMoviesTabs } from './AiMoviesTabs';
import { LoadingState } from './LoadingState';
import { MoviesChatGptApi } from '../../api/MoviesChatGptApi';
import { SimilarMoviesLsApi } from '../../api/SimilarMoviesLsApi';
import { useAsyncHandler } from '../../hooks/useAsyncHandler';
import { TheMovieDbMovie } from '../../models/TheMovieDbMovie';
import { Amplitude } from '../../services/Amplitude';

interface Props {
  title: string | string[];
}

export const SimilarMoviesBox: FC<Props> = observer(({ title }) => {
  const similarRef = useRef<HTMLDivElement>(null);

  const handleFindSimilarMovies = useCallback(async () => {
    Amplitude.track('FF__SIMILAR_BUTTON__CLICK', { title });
    similarRef.current?.scrollIntoView();
    if (!title || !title.length) {
      return '';
    }

    const titleStr = Array.isArray(title) ? title.join(', ') : title;
    const titleId = TheMovieDbMovie.buildTitleId(titleStr);

    const similarFromLs = await SimilarMoviesLsApi.getSimilarMovies(titleId);
    if (similarFromLs) {
      return similarFromLs;
    }

    const similarFromGpt = await MoviesChatGptApi.getSimilarMovies(title);

    await SimilarMoviesLsApi.saveSimilarMovies(titleId, similarFromGpt);

    return similarFromGpt;
  }, [title]);

  const { isLoading, error, asyncHandler, result } = useAsyncHandler(handleFindSimilarMovies);

  return (
    <Paper elevation={3} sx={{ mb: 1, mt: 1, p: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Tooltip title="Search by AI GPT">
          <Button color="primary" startIcon={<SmartToyIcon />} variant="contained" onClick={asyncHandler}>
            Find Similar Movies
          </Button>
        </Tooltip>
      </Box>
      <Box ref={similarRef}>
        <LoadingState error={error} isError={!!error} isLoading={isLoading} isSuccess={!!result}>
          {result && (
            <Box sx={{ m: 1, p: 1 }}>
              <AiMoviesTabs content={result} />
            </Box>
          )}
        </LoadingState>
      </Box>
    </Paper>
  );
});
