import { ChatGptApi } from './ChatGptApi';
import { LocalStorageApi } from './LocalStorageApi';

export namespace ChatLsApi {
  const KEY = 'DEFAULT';
  const ls = new LocalStorageApi('CHAT', (value: ChatGptApi.IMessage[]) => value);

  export const getMessages = async (): Promise<ChatGptApi.IMessage[]> => ((await ls.getEntry(KEY)) ?? []).reverse();

  export const addMessage = async (message: ChatGptApi.IMessage) => {
    const messages = await getMessages();

    return await ls.saveEntry(KEY, [...messages, message]);
  };
}
