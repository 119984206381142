import { ChatGptApi } from './ChatGptApi';

export namespace MoviesChatGptApi {
  export const groupMovies = async (moviesTitle: string[]) => {
    const titles = moviesTitle.join(', ');

    return await ChatGptApi.sendChatMessage([
      {
        content: `Hi ChatGPT, I have a list of movies that I would like to group by logic. The list includes: ${titles}. Can you please group these movies by logic? Thank you!`,
        role: 'user'
      }
    ]);
  };

  export const getSimilarMovies = async (movieTitle: string | string[]) =>
    await ChatGptApi.sendChatMessage([
      {
        content: Array.isArray(movieTitle)
          ? `Hey ChatGPT, can you identify something in common between these movies: "${movieTitle.join(
              ', '
            )}, and recommend some similar movies based on that common factor?"`
          : `Hey ChatGPT, can you recommend some movies similar to "${movieTitle}"`,
        role: 'user'
      }
    ]);

  export const getMoviesList = async (prompt: string) =>
    await ChatGptApi.sendChatMessage([
      {
        content: 'Find movies list from next message prompt. Order by popularity IMDB',
        role: 'system'
      },
      {
        content: `${prompt}`,
        role: 'user'
      }
    ]);
}
