import React, { useMemo } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AppRouting } from './AppRouting';
import { AppInit } from './components/AppInit';
import { APP_THEME } from './constants/APP_THEME';
import { Amplitude } from './services/Amplitude';
import { Domain } from './services/Domain';
import { Hotjar } from './services/Hotjar';
import { RootStoreProvider } from './stores/root-store-provider';

if (Domain.isProduction()) {
  Amplitude.init();
  Hotjar.init();
}

export const App = () => {
  const queryClient = useMemo(() => new QueryClient(), []);

  return (
    <QueryClientProvider client={queryClient}>
      <RootStoreProvider>
        <AppInit />
        <ThemeProvider theme={APP_THEME}>
          <CssBaseline enableColorScheme />
          <AppRouting />
        </ThemeProvider>
      </RootStoreProvider>
    </QueryClientProvider>
  );
};
