import { FC } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { InputBaseProps } from '@mui/material/InputBase/InputBase';
import Paper from '@mui/material/Paper';

interface Props extends InputBaseProps {
  onSubmitByAi: () => void;
  onSubmitByName: () => void;
  onClear: () => void;
}

export const SearchField: FC<Props> = ({ onSubmitByAi, onSubmitByName, onClear, ...props }) => (
  <Paper
    component="form"
    sx={{ alignItems: 'center', display: 'flex', p: '2px 4px' }}
    onSubmit={e => {
      e.preventDefault();
      onSubmitByName();
    }}
  >
    <InputBase sx={{ flex: 1, ml: 1 }} {...props} />
    <IconButton aria-label="clear" sx={{ p: '10px' }} onClick={onClear}>
      <ClearIcon />
    </IconButton>
    <Divider orientation="vertical" sx={{ height: 28, m: 0.5 }} />
    <Tooltip title="Search">
      <IconButton aria-label="search" color="primary" sx={{ p: '10px' }} type="submit">
        <SearchIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title="Search by AI GPT">
      <IconButton aria-label="Search with AI" color="primary" sx={{ p: '10px' }} onClick={onSubmitByAi}>
        <SmartToyIcon />
      </IconButton>
    </Tooltip>
  </Paper>
);
