import React, { FC } from 'react';

import { AppBar, Container, Toolbar } from '@mui/material';

import { BurgerMenu } from './BurgerMenu';
import { MobileLogo } from './MobileLogo';
import { NonMobileLogo } from './NonMobileLogo';
import { ProfileMenu } from './ProfileMenu';
import { TopMenu } from './TopMenu';
import { Amplitude } from '../../../services/Amplitude';

export const AppTopBar: FC = () => {
  const menuClickHandler = (link: string) => {
    Amplitude.track('FF__TOP_MENU__CLICK', { link });
  };

  return (
    <AppBar color="transparent" position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <NonMobileLogo />
          <BurgerMenu onMenuClick={menuClickHandler} />
          <MobileLogo />
          <TopMenu onMenuClick={menuClickHandler} />
          <ProfileMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
