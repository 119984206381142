import { IMovieParsed } from '../interfaces/IMovieParsed';
import { isExist, Nullable } from '../types/assert';

export namespace ChatGptMoviesListsService {
  interface IFilm {
    name: string;
    endLine: string;
  }

  const mapLineToFilm = (separator: string) => (line: string) =>
    line.slice(line.indexOf(separator) + 1, line.indexOf('(')).trim();

  export const findFilmsInText = (text: string) => {
    const lines = text.split('\n');
    const separator = text[1];
    const response = { films: [] as IFilm[], lines, separator, text };

    if (text[0] !== '1') {
      return response;
    }

    response.films = lines.map(line => {
      const name = mapLineToFilm(separator)(line);
      const endLine = line.split(name)[1];

      return { endLine, name };
    });

    return response;
  };

  const isLineHasNextListElement = (listIndex: number, line: string) =>
    line.startsWith(`${listIndex}. `) || line.startsWith(`${listIndex}) `);

  const extractFilmName = (line: string) => {
    const firstSpaceIndex = line.indexOf(' ') + 1;
    const lastIndex = line.indexOf('(') !== -1 ? line.indexOf('(') - 1 : undefined;

    const firstWord = line.substring(0, firstSpaceIndex).trim();
    const firstIndex = /[a-zA-Z]/g.test(firstWord) ? 0 : firstSpaceIndex;

    const fullFileName = line
      .substring(firstIndex, lastIndex)
      .replace(/(^"|"$)/g, '')
      .replace(/(^'|'$)/g, '')
      .trim();

    return fullFileName.at(-1) === ':' ? fullFileName.slice(0, -1) : fullFileName;
  };

  const extractYear = (line: string) => {
    const [, year] = line.match(/\(([0-9]+)\)/) ?? [];
    if (!year || isNaN(+year)) {
      return null;
    }

    return +year;
  };

  export const buildUrl = (filmName: string, year: Nullable<number>) => {
    const url = `/search?q=${encodeURI(filmName)}`;
    if (!year) {
      return url;
    }

    return `${url}&y=${year}`;
  };

  export const buildName = (filmUrl: string) => filmUrl.replaceAll('_', ' ');

  const buildFilmLink = (filmName: string, year: Nullable<number>) => `[${filmName}](${buildUrl(filmName, year)})`;

  const isIMovieParsed = (
    movie:
      | IMovieParsed
      | {
          filmLink: null;
          filmName: null;
          line: string;
          year: null;
        }
  ): movie is IMovieParsed => isExist(movie.filmLink) && isExist(movie.filmName);

  export const addLinksToResponse = (text: string) => {
    let listIndex = 1;
    const movies: IMovieParsed[] = text
      .split('\n')
      .map(line => {
        if (!isLineHasNextListElement(listIndex, line)) {
          listIndex = 1;

          return { filmLink: null, filmName: null, line, year: null };
        }
        const filmName = extractFilmName(line);
        const year = extractYear(line);
        const filmLink = buildFilmLink(filmName, year);
        const markdown = line.replaceAll(filmName, filmLink);
        listIndex++;

        return {
          filmLink,
          filmName,
          markdown,
          q: buildUrl(filmName, year),
          year
        };
      })
      .filter(isIMovieParsed);

    const markdown = movies.map(({ markdown: m }) => m).join('\n') || text;

    return { markdown, movies };
  };

  export const parseMoviesGroups = (response: string) =>
    response.split('\n\n').map(group => {
      const [title, ...movies] = group.split('\n');

      return { movies: movies.map(extractFilmName), title: extractFilmName(title) };
    });
}
