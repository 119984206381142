import { useCallback, useEffect, useState } from 'react';

import { Nullable } from '../types/assert';

export const useAsyncHandler = <T>(handler: () => Promise<T>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Nullable<Error>>(null);
  const [result, setResult] = useState<Nullable<T>>(null);

  useEffect(
    () => () => {
      setIsLoading(false);
      setError(null);
      setResult(null);
    },
    []
  );

  const asyncHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const _result = await handler();
      setResult(_result);

      return _result;
    } catch (_error) {
      setError(_error as Error);
      throw _error;
    } finally {
      setIsLoading(false);
    }
  }, [handler]);

  return {
    asyncHandler,
    error,
    isLoading,
    result
  };
};
