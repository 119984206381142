import { FC } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AppLayout } from './components/templates/AppLayout';
import { ROUTES } from './constants/ROUTES';
import { ChatPage } from './pages/ChatPage';
import { E404Page } from './pages/E404Page';
import { ErrorPage } from './pages/ErrorPage';
import { FavoritesPage } from './pages/FavoritesPage';
import { FilmPage } from './pages/FilmPage';
import { HomePage } from './pages/HomePage';
import { SearchPage } from './pages/SearchPage';
import { WatchLaterPage } from './pages/WatchLaterPage';

export const AppRouting: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<AppLayout />} path={ROUTES.HOME}>
        <Route index element={<HomePage />} />
        <Route element={<FavoritesPage />} path={ROUTES.FAVORITES} />
        <Route element={<WatchLaterPage />} path={ROUTES.WATCH_LATER} />
        <Route element={<FilmPage />} path={`${ROUTES.FILM}/:id`} />
        <Route element={<SearchPage />} path={ROUTES.SEARCH} />

        <Route element={<ChatPage />} path={ROUTES.CHAT} />

        <Route element={<ErrorPage />} path={ROUTES.ERROR} />
        <Route element={<E404Page />} path="*" />
      </Route>
    </Routes>
  </BrowserRouter>
);
