import { FC } from 'react';

import { Container } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { favoritesFilmsLsApi } from '../../api/KindsFilmsLsApi';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { useRootStore } from '../../stores/root-store-provider';
import { FilmsGroup } from '../organisms/FilmsGroup';

interface Props {
  todo?: boolean;
}

export const FavoriteFilms: FC<Props> = observer(() => {
  const { favoriteFilmsStore } = useRootStore();
  const { groups } = favoriteFilmsStore;

  useAsyncEffect(
    async () => await favoritesFilmsLsApi.getFilms(),
    value => favoriteFilmsStore.setMovies(value)
  );

  const ungroupedMovies = favoriteFilmsStore.getMoviesWithoutGroup();

  return (
    <Container>
      {groups.map(group => (
        <FilmsGroup key={group} group={group} movies={favoriteFilmsStore.getMoviesFromGroup(group)} />
      ))}
      <FilmsGroup expanded group="Ungrouped movies" movies={ungroupedMovies} />
    </Container>
  );
});
