import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';

import { AiMoviesBox } from './AiMoviesBox';
import { AiTextBox } from './AiTextBox';
import { ChatGptMoviesListsService } from '../../services/ChatGptMoviesListsService';

enum Tabs {
  AiText = 'AI Text',
  Movies = 'Movies'
}

interface Props {
  content: string;
}

export const AiMoviesTabs: FC<Props> = ({ content }) => {
  const { markdown, movies } = useMemo(() => ChatGptMoviesListsService.addLinksToResponse(content), [content]);

  const [tab, setTab] = useState(movies.length ? Tabs.Movies : Tabs.AiText);

  useEffect(() => {
    setTab(movies.length ? Tabs.Movies : Tabs.AiText);
  }, [movies]);

  const handleTabChange = (event: SyntheticEvent, newValue: Tabs) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ typography: 'body1', width: '100%' }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList aria-label="lab API tabs example" onChange={handleTabChange}>
            <Tab label={Tabs.AiText} value={Tabs.AiText} />
            <Tab label={Tabs.Movies} value={Tabs.Movies} />
          </TabList>
        </Box>
        <TabPanel value={Tabs.AiText}>
          <AiTextBox markdown={markdown} />
        </TabPanel>
        <TabPanel value={Tabs.Movies}>
          <AiMoviesBox movies={movies} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
