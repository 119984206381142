import { FC } from 'react';

import { Container } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { SearchExamples } from '../components/molecules/SearchExamples';

export const HomePage: FC = () => (
  <Container>
    <Typography variant="h2">
      Welcome to&nbsp;beta-testing of&nbsp;the <b>Fury-Films</b>!
    </Typography>

    <Paper elevation={24} sx={{ p: 2 }}>
      <Typography variant="body1">
        The tool is a comprehensive movie recommendation and management platform that allows users to search for movies
        using Chat GPT, find similar movies to a specific film or a group of films, create personalized favourites
        lists, and a watch-later list.
      </Typography>
      <Typography variant="body1">
        Using its personalized recommendation feature, the platform suggests movies to the user based on their viewing
        history and preferences. The search function is powered by Chat GPT, making it easy to find movies using natural
        language queries.
      </Typography>
      <Typography variant="body1">
        Additionally, users can create their own personalized lists, such as a list of movies they want to watch later
        or a list of their favourite movies. This platform also offers personalized search options based on the user's
        search history, preferences, and activity on the platform. Overall, this tool is a comprehensive movie
        management platform that aims to provide users with a personalized and seamless movie management experience.
      </Typography>
      <Typography variant="h3">You can start with some search examples:</Typography>
      <SearchExamples />
    </Paper>
  </Container>
);
