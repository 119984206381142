import { Badge } from '@mui/material';
import { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

export const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    color: 'rgba(0, 0, 0, 0.3)',
    fontStyle: 'italic',
    padding: '0 14px',
    right: -20,
    top: 8
  }
}));
