import { createTheme } from '@mui/material';
import { blue, grey, lightBlue, orange } from '@mui/material/colors';

/*
$primary-color-dark:   #00796B
$primary-color:        #009688
$primary-color-light:  #B2DFDB
$primary-color-text:   #FFFFFF
$accent-color:         #03A9F4
$primary-text-color:   #212121
$secondary-text-color: #757575
$divider-color:        #BDBDBD
 */

export const APP_THEME = createTheme({
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: lightBlue[800]
        }
      }
    }
  },
  palette: {
    // action: {
    //   active: lightBlue[200],
    //   focus: lightBlue[600],
    //   focusOpacity: 1,
    //   hover: lightBlue[100],
    //   hoverOpacity: 0.7,
    //   selected: lightBlue[300],
    //   selectedOpacity: 1
    // },
    // mode: 'dark',
    primary: {
      // main: teal['400']
      main: orange.A700
    },
    secondary: {
      contrastText: 'white',
      main: blue.A400
    },
    // divider: teal[900],
    // background: {
    //   default: teal[900],
    //   paper: teal[800]
    // }
    text: {
      primary: grey[900],
      secondary: lightBlue[800]
    }
  },
  typography: {
    fontSize: 14
  }
});

const { breakpoints } = APP_THEME;

APP_THEME.typography.h1 = {
  fontSize: '5rem',
  lineHeight: '1.2',
  [breakpoints.down('md')]: {
    fontSize: '2.5rem'
  },
  [breakpoints.down('sm')]: {
    fontSize: '2rem'
  }
};

APP_THEME.typography.h2 = {
  fontSize: '4rem',
  [breakpoints.down('md')]: {
    fontSize: '2rem'
  },
  [breakpoints.down('sm')]: {
    fontSize: '1.8rem'
  }
};

APP_THEME.typography.h3 = {
  fontSize: '3rem',
  [breakpoints.down('md')]: {
    fontSize: '1.8rem'
  },
  [breakpoints.down('sm')]: {
    fontSize: '1.6rem'
  }
};

APP_THEME.typography.h4 = {
  fontSize: '2.6rem',
  [breakpoints.down('md')]: {
    fontSize: '1.6rem'
  },
  [breakpoints.down('sm')]: {
    fontSize: '1.4rem'
  }
};

APP_THEME.typography.h5 = {
  fontSize: '2rem',
  [breakpoints.down('md')]: {
    fontSize: '1.4rem'
  },
  [breakpoints.down('sm')]: {
    fontSize: '1.2rem'
  }
};

APP_THEME.typography.h6 = {
  fontSize: '1.2rem',
  [breakpoints.down('md')]: {
    fontSize: '1.2rem'
  },
  [breakpoints.down('sm')]: {
    fontSize: '1.1rem'
  }
};
