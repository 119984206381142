import { FC } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';

import { FilmsList } from './FilmsList';
import { SimilarMoviesBox } from './SimilarMoviesBox';
import { TheMovieDbMovie } from '../../models/TheMovieDbMovie';

interface Props {
  group: string;
  movies: TheMovieDbMovie[];
  expanded?: boolean;
}

export const FilmsGroup: FC<Props> = ({ group, movies, expanded }) => {
  if (!movies.length) {
    return null;
  }

  return (
    <Accordion defaultExpanded={expanded} elevation={24}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h4">{group}</Typography>
        <Typography sx={{ color: 'text.secondary' }}>({movies.length})</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FilmsList movies={movies} />
        <SimilarMoviesBox title={movies.map(m => m.title)} />
      </AccordionDetails>
    </Accordion>
  );
};
