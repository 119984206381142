import { FC } from 'react';

import { Container } from '@mui/material';

import { TheMovieDbMovie } from '../../models/TheMovieDbMovie';
import { MovieCard } from '../molecules/MovieCard';

interface Props {
  movies: TheMovieDbMovie[];
}

export const FilmsList: FC<Props> = ({ movies }) => (
  <Container sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
    {movies.map(movie => (
      <MovieCard key={movie.id} movie={movie} />
    ))}
  </Container>
);
