import { FC, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Box, CardActionArea, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { FilmGroupsSelector } from './forms-elements/FilmGroupsSelector';
import { POSTER_CARD_SIZE } from '../../constants/IMAGES';
import { ROUTES } from '../../constants/ROUTES';
import { TheMovieDbMovie } from '../../models/TheMovieDbMovie';
import { Amplitude } from '../../services/Amplitude';
import { Nullable } from '../../types/assert';
import { ExpandMore } from '../atoms/ExpandMore';

interface Props {
  movie: TheMovieDbMovie;
}

export const MovieCard: FC<Props> = observer(({ movie }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    Amplitude.track('FF__MOVIE_CARD__EXPAND', { expanded: !expanded });
    setExpanded(!expanded);
  };

  const handleFavoriteClick = async () => {
    Amplitude.track('FF__MOVIE_CARD__FAVORITE__TOGGLE', { movie });
    await movie.toggleFavorite();
  };

  const handleWatchLaterClick = async () => {
    Amplitude.track('FF__MOVIE_CARD__WATCH_LATER__TOGGLE', { movie });
    await movie.toggleWatchLater();
  };

  const handleCardClick = () => {
    Amplitude.track('FF__MOVIE_CARD__CLICK', { movie });

    return navigate(`${ROUTES.FILM}/${movie.id}`);
  };

  const handleGroupChange = async (group: Nullable<string>) => {
    Amplitude.track('FF__MOVIE_CARD__GROUP__CHANGE', { group, movie });

    return await movie.setGroup(group);
  };

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', m: 1, width: POSTER_CARD_SIZE.WIDTH + 20 }}>
      <CardActionArea onClick={handleCardClick}>
        {movie.posterPath && (
          <CardMedia
            alt={movie.title}
            component="img"
            height={POSTER_CARD_SIZE.HEIGHT}
            image={movie.posterPath}
            width={POSTER_CARD_SIZE.WIDTH}
          />
        )}
        <CardContent>
          <Typography color="text.secondary" variant="h6">
            {movie.title} ({movie.releaseDate?.getFullYear()})
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing>
        <Tooltip title="Favorite">
          <IconButton aria-label="Favorite" onClick={handleFavoriteClick}>
            <FavoriteIcon sx={{ color: movie.isFavorite ? 'red' : 'inherit' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Watch Later">
          <IconButton aria-label="Watch Later" onClick={handleWatchLaterClick}>
            <WatchLaterIcon sx={{ color: movie.isWatchLater ? 'red' : 'inherit' }} />
          </IconButton>
        </Tooltip>
        <ExpandMore aria-expanded={expanded} aria-label="show more" expand={expanded} onClick={handleExpandClick}>
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Box sx={{ flex: 1 }} />
      <FilmGroupsSelector value={movie.group} onChange={handleGroupChange} />
      <Collapse unmountOnExit in={expanded} timeout="auto">
        <CardContent>
          <Typography variant="body2">{movie.overview}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
});
