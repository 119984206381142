import { FC } from 'react';

import FavoriteIcon from '@mui/icons-material/Favorite';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Box, Container, Paper, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';

import { SimilarMoviesBox } from './SimilarMoviesBox';
import { POSTER_SIZE } from '../../constants/IMAGES';
import { TheMovieDbMovie } from '../../models/TheMovieDbMovie';

interface Props {
  film: TheMovieDbMovie;
}

export const FilmDetails: FC<Props> = observer(({ film }) => {
  const handleFavoriteClick = async () => {
    await film.toggleFavorite();
  };

  const handleWatchLaterClick = async () => {
    await film.toggleWatchLater();
  };

  return (
    <Container>
      <Paper elevation={24} sx={{ mb: 1, mt: 1, p: 1 }}>
        <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h1">{film.title}</Typography>
          <Box>
            <Tooltip title="Favorite">
              <IconButton aria-label="add to favorites" onClick={handleFavoriteClick}>
                <FavoriteIcon sx={{ color: film.isFavorite ? 'red' : 'inherit' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Watch Later">
              <IconButton aria-label="add to favorites" onClick={handleWatchLaterClick}>
                <WatchLaterIcon sx={{ color: film.isWatchLater ? 'red' : 'inherit' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box sx={{ display: { md: 'flex', sm: 'block' } }}>
          {film.posterPath && (
            <Box>
              <img alt={film.title} height={POSTER_SIZE.HEIGHT} src={film.posterPath} width={POSTER_SIZE.WIDTH} />
            </Box>
          )}
          <Box sx={{ flex: 1, ml: 1 }}>
            <Typography variant="body1">
              <b>Original title:</b>
            </Typography>
            <Typography component="h2" variant="h5">
              {film.originalTitle}
            </Typography>
            <Typography variant="body1">
              <b>Release date:</b> {film.releaseDate?.toLocaleDateString()}
            </Typography>
            <Typography variant="body1">
              <b>Original language:</b> {film.originalLanguage.toUpperCase()}
            </Typography>

            <Typography variant="body1">{film.overview}</Typography>
          </Box>
        </Box>
      </Paper>

      <SimilarMoviesBox title={film.title} />
    </Container>
  );
});
