import { FC } from 'react';

import { useSearchFilmsByName } from '../../api/hooks/useSearchFilmsByName';
import { IMovieParsed } from '../../interfaces/IMovieParsed';
import { TheMovieDbMovie } from '../../models/TheMovieDbMovie';
import { LoadingMovieCard } from '../molecules/LoadingMovieCard';
import { MovieCard } from '../molecules/MovieCard';

interface Props {
  movie: IMovieParsed;
}

export const LoadingMovieCardBox: FC<Props> = ({ movie }) => {
  const { isLoading, data } = useSearchFilmsByName(movie.filmName, movie.year);

  const film = !isLoading && data && data.results[0] ? new TheMovieDbMovie(data.results[0]) : null;

  return film ? <MovieCard movie={film} /> : <LoadingMovieCard movie={movie} />;
};
