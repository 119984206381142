import React, { FC, HTMLAttributeAnchorTarget, MouseEvent, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router';

import { MENU_PAGES } from './MENU_PAGES';

interface Props {
  onMenuClick: (link: string) => void;
}

export const BurgerMenu: FC<Props> = ({ onMenuClick }) => {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (link: string, target?: HTMLAttributeAnchorTarget | 'backdropClick') => {
    setAnchorElNav(null);
    if (target === 'backdropClick') {
      return;
    }
    onMenuClick(link);
    if (target) {
      window.open(link, target);
    } else {
      navigate(link);
    }
  };

  return (
    <Box sx={{ display: { md: 'none', xs: 'flex' }, flexGrow: 1 }}>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        aria-label="account of current user"
        color="inherit"
        size="large"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorElNav}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        id="menu-appbar"
        open={Boolean(anchorElNav)}
        sx={{
          display: { md: 'none', xs: 'block' }
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
        onClose={handleCloseNavMenu}
      >
        {MENU_PAGES.map(({ link, icon, burgerTitle, title, target }) => (
          <MenuItem key={link} onClick={() => handleCloseNavMenu(link, target)}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText>{burgerTitle ?? title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
