import React, { HTMLAttributeAnchorTarget, ReactNode } from 'react';

import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

import { ROUTES } from '../../../constants/ROUTES';

interface IMenu {
  link: string;
  title?: string;
  burgerTitle?: string;
  target?: HTMLAttributeAnchorTarget;
  icon?: ReactNode;
}

export const MENU_PAGES: Array<IMenu> = [
  { icon: <SearchIcon />, link: ROUTES.SEARCH, title: 'Search' },
  { icon: <FavoriteIcon />, link: ROUTES.FAVORITES, title: 'Favorites' },
  { icon: <WatchLaterIcon />, link: ROUTES.WATCH_LATER, title: 'Watch Later' }
  // { link: ROUTES.CHAT, title: 'Chat' }
];
