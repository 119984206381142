import React, { FC, ReactNode } from 'react';

import { ErrorBox } from '../molecules/ErrorBox';
import { LoadingBox } from '../molecules/LoadingBox';

interface Props {
  children: ReactNode;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: Error | null | unknown;
}

export const LoadingState: FC<Props> = ({ children, isSuccess, isLoading, isError, error }: Props) => {
  if (isLoading) {
    return <LoadingBox />;
  }

  if (isError) {
    return <ErrorBox error={error ?? 'Something went wrong'} />;
  }

  if (isSuccess) {
    return <>{children}</>;
  }

  return null;
};
