import { FC } from 'react';

import { useParams } from 'react-router';

import { FilmDetailsContainer } from '../components/templates/FilmDetailsContainer';
import { TheMovieDbMovie } from '../models/TheMovieDbMovie';

export const FilmPage: FC = () => {
  const { id } = useParams();

  if (!id) {
    throw new Error('No id provided');
  }

  return <FilmDetailsContainer {...TheMovieDbMovie.parseId(id)} />;
};
