import { action, makeObservable, observable } from 'mobx';

import { FilmsLocalApi } from '../api/FilmsLocalApi';
import { favoritesFilmsLsApi, watchLaterFilmsLsApi } from '../api/KindsFilmsLsApi';
import { TheMovieDbMovieRaw } from '../interfaces/ITheMovieDbResponseRaw';
import { Nullable } from '../types/assert';
import { capitalize } from '../types/strings';

export class TheMovieDbMovie {
  @observable id: string;

  @observable adult: boolean;
  @observable backdropPath: Nullable<string>;
  @observable genreIds: number[];
  @observable idTheMovie: number;
  @observable originalLanguage: string;
  @observable originalTitle: string;
  @observable overview: string;
  @observable popularity: number;
  @observable posterPath: Nullable<string>;
  @observable releaseDate: Nullable<Date>;
  @observable title: string;
  @observable video: boolean;
  @observable voteAverage: number;
  @observable voteCount: number;

  @observable isFavorite = false;
  @observable isWatchLater = false;
  @observable group: Nullable<string> = null;

  readonly #raw: TheMovieDbMovieRaw;

  constructor(raw: TheMovieDbMovieRaw) {
    makeObservable(this);

    this.#raw = raw;

    this.adult = raw.adult;
    this.backdropPath = raw.backdrop_path ? `https://image.tmdb.org/t/p/w500${raw.backdrop_path}` : null;
    this.genreIds = raw.genre_ids;
    this.idTheMovie = raw.id;
    this.originalLanguage = raw.original_language;
    this.originalTitle = raw.original_title;
    this.overview = raw.overview;
    this.popularity = raw.popularity;
    this.posterPath = raw.poster_path ? `https://image.tmdb.org/t/p/w500${raw.poster_path}` : null;
    this.releaseDate = raw.release_date && new Date(raw.release_date) ? new Date(raw.release_date) : null;
    this.title = raw.title;
    this.video = raw.video;
    this.voteAverage = raw.vote_average;
    this.voteCount = raw.vote_count;
    this.group = raw.group;

    this.id = TheMovieDbMovie.buildId(this.idTheMovie, raw.title ?? raw.original_title ?? '');

    void this.loadFromStorage();
  }

  get raw() {
    return this.#raw;
  }

  static parseId(id: string) {
    const [idTheMovieString, idTitle] = id.split('__');
    const title = capitalize(`${idTitle ?? id}`.replace(/-/g, ' '));

    const idTheMovie = Number(idTheMovieString);

    return {
      id,
      idTheMovie: !isNaN(idTheMovie) ? idTheMovie : null,
      title
    };
  }

  static buildTitleId(title: string | number | null) {
    return `${title}`
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 ]/g, '-')
      .replace(/\s/g, '-')
      .replace(/--/g, '-')
      .replace(/-/g, ' ')
      .trim()
      .replace(/ /g, '-');
  }

  static buildId(id: number, title: string | number | null) {
    return `${id}__${this.buildTitleId(title)}`;
  }

  @action
  async loadFromStorage() {
    this.isFavorite = await favoritesFilmsLsApi.loadIsKind(this.id);
    this.isWatchLater = await watchLaterFilmsLsApi.loadIsKind(this.id);
  }

  @action
  async toggleFavorite() {
    this.isFavorite = !this.isFavorite;
    await favoritesFilmsLsApi.setKind(this.id, this.isFavorite, this);
  }

  @action
  async toggleWatchLater() {
    this.isWatchLater = !this.isWatchLater;
    await watchLaterFilmsLsApi.setKind(this.id, this.isWatchLater, this);
  }

  @action
  async setGroup(group: Nullable<string>) {
    this.group = group;
    this.raw.group = group;
    await FilmsLocalApi.saveFilm(this.id, this.raw);
  }
}
