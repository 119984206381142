import { FC } from 'react';

import { FormControl, MenuItem, Select } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useRootStore } from '../../../stores/root-store-provider';
import { Nullable } from '../../../types/assert';

interface Props {
  value: Nullable<string>;
  onChange: (value: Nullable<string>) => void;
}

export const FilmGroupsSelector: FC<Props> = observer(({ value, onChange }) => {
  const { favoriteFilmsStore } = useRootStore();
  const { groups } = favoriteFilmsStore;

  if (!groups.length) {
    return null;
  }

  return (
    <FormControl fullWidth size="small" sx={{ mb: 0 }} variant="standard">
      <Select label="Group" sx={{ pl: 1 }} value={value} onChange={e => onChange(e.target.value)}>
        {groups.map(_group => (
          <MenuItem key={_group} value={_group}>
            {_group}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
