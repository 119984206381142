import { action, makeObservable, observable } from 'mobx';

import { GroupsMoviesLsApi } from '../api/GroupsMoviesLsApi';
import { TheMovieDbMovie } from '../models/TheMovieDbMovie';
import { ChatGptMoviesListsService } from '../services/ChatGptMoviesListsService';
import { Nullable, Optional } from '../types/assert';

export interface IFavoriteFilmsStore {
  movies: TheMovieDbMovie[];
  groups: string[];
  setMovies: (movies: TheMovieDbMovie[]) => void;
  getMoviesNames: () => string[];
  setGroups: (groups: string[]) => void;
  groupMovies: (group: Nullable<string>, movies: string[]) => void;
  setGroupedMovies: (response: string) => Promise<string[]>;
  getMoviesFromGroup: (group: Optional<string>) => TheMovieDbMovie[];
  getMoviesWithoutGroup: () => TheMovieDbMovie[];
}

export class FavoriteFilmsStore implements IFavoriteFilmsStore {
  @observable movies: TheMovieDbMovie[] = [];
  @observable groups: string[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  setMovies = (movies: TheMovieDbMovie[]) => {
    this.movies = movies;
  };

  getMoviesNames() {
    return this.movies.map(movie => movie.title);
  }

  getMovieByTitle(title: string) {
    return this.movies.find(movie => movie.title === title || movie.originalTitle === title);
  }

  @action
  groupMovies(group: Nullable<string>, movies: string[]) {
    movies.forEach(movie => {
      const movieModel = this.getMovieByTitle(movie);
      if (!movieModel) {
        return;
      }
      void movieModel.setGroup(group);
    });
  }

  @action
  setGroups(groups: string[]) {
    this.groups = groups;
  }

  @action
  async setGroupedMovies(response: string) {
    if (!response) {
      return [];
    }
    const groups = ChatGptMoviesListsService.parseMoviesGroups(response);
    if (!groups[0].title || !groups[0].movies.length) {
      return [];
    }
    groups.forEach(({ title, movies }) => this.groupMovies(title, movies));
    this.groups = groups.map(({ title }) => title);
    await GroupsMoviesLsApi.saveGroups(this.groups);

    return this.groups;
  }

  getMoviesFromGroup(group: Optional<string>) {
    return this.movies.filter(movie => movie.group === group);
  }

  getMoviesWithoutGroup() {
    const nullMovies = this.getMoviesFromGroup(null);
    if (nullMovies.length) {
      return nullMovies;
    }

    const undefinedMovies = this.getMoviesFromGroup(undefined);
    if (undefinedMovies.length) {
      return undefinedMovies;
    }

    return this.getMoviesFromGroup('');
  }
}
