import { FC } from 'react';

import { Container, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

export const ErrorPage: FC = observer(() => (
  <Container>
    <Typography variant="h1">
      Something went wrong <br />
      ;-(
    </Typography>
  </Container>
));
