import { FC } from 'react';

import { Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { WatchLaterFilms } from '../components/templates/WatchLaterFilms';

export const WatchLaterPage: FC = () => (
  <Container>
    <Typography sx={{ ml: 3, mr: 3 }} variant="h1">
      Watch Later
    </Typography>
    <WatchLaterFilms />
  </Container>
);
